import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3c086200"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "cell-body my-flex my-rows my-nowrap"
};
const _hoisted_2 = {
  class: "my-avatar my-bot-icon"
};
const _hoisted_3 = {
  key: 0,
  src: "/image/robot/icon_bot.png",
  class: "my-avatar-img"
};
const _hoisted_4 = {
  key: 1,
  src: "/image/robot/icon_bot_0.png",
  class: "my-avatar-img"
};
const _hoisted_5 = {
  class: "my-bot-body my-flex my-columns"
};
const _hoisted_6 = {
  class: "my-bot-top my-flex my-rows my-wrap my-space-between my-nowrap"
};
const _hoisted_7 = {
  class: "bot-name"
};
const _hoisted_8 = {
  key: 0,
  class: "bot-state text-orange"
};
const _hoisted_9 = {
  key: 1,
  class: "bot-state text-blue"
};
const _hoisted_10 = {
  key: 2,
  class: "bot-state text-orange"
};
const _hoisted_11 = {
  key: 3,
  class: "bot-state text-black-2"
};
const _hoisted_12 = {
  class: "my-bot-content my-flex my-rows my-wrap my-space-between my-nowrap"
};
const _hoisted_13 = {
  class: "text-green font-12"
};
const _hoisted_14 = {
  class: "text-orange"
};
const _hoisted_15 = {
  class: "my-bot-content my-flex my-rows my-wrap my-space-between my-nowrap",
  style: {
    "margin-bottom": "10px"
  }
};
const _hoisted_16 = {
  class: "text-blue font-12"
};
const _hoisted_17 = {
  class: "bot-time text-black-3"
};
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { getPledgeList } from "@/api/index.js";
import { useUserStore } from '@/stores/index.js';
export default {
  __name: 'history',
  setup(__props) {
    const router = useRouter();
    const userStore = useUserStore();
    const userInfo = ref(userStore.userInfo);
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const data = {
      user_addr: userInfo.value.user_addr,
      limit: 100
    };
    const onClickLeft = () => {
      console.log('bot');
      router.push({
        path: '/bot'
      });
    };
    const empty = ref(false);
    onMounted(() => {
      console.log('xx');
    });
    const is_auth = ref(false);
    const onLoad = () => {
      getPledgeList(data).then(data => {
        let res = data.data.data.data;
        console.log(res);
        res.forEach((item, key) => {
          list.value[key] = item;
          list.value[key].icon = item.name.toLowerCase();
          list.value[key].gold = parseInt(item.amount).toFixed(2);
          list.value[key].receiveds = parseInt(item.received).toFixed(3);
        });
        console.log(list.value);
        loading.value = false;
        finished.value = true;
      });
    };
    return (_ctx, _cache) => {
      const _component_van_nav_bar = _resolveComponent("van-nav-bar");
      const _component_van_image = _resolveComponent("van-image");
      const _component_van_cell = _resolveComponent("van-cell");
      const _component_van_list = _resolveComponent("van-list");
      const _component_van_empty = _resolveComponent("van-empty");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_nav_bar, {
        fixed: true,
        placeholder: true,
        title: "history",
        "left-text": "",
        "left-arrow": "",
        onClickLeft: onClickLeft,
        border: false
      }), _createVNode(_component_van_list, {
        loading: loading.value,
        "onUpdate:loading": _cache[0] || (_cache[0] = $event => loading.value = $event),
        finished: finished.value,
        "finished-text": " ",
        "loading-text": "loading...",
        onLoad: onLoad
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.value, item => {
          return _openBlock(), _createBlock(_component_van_cell, {
            key: item,
            border: false
          }, {
            default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_van_image, {
              round: "",
              width: "2.2rem",
              height: "2.2rem",
              src: '/image/icons3x/' + item.icon + '_3x.png'
            }, null, 8, ["src"]), item.status == 1 ? (_openBlock(), _createElementBlock("img", _hoisted_3)) : (_openBlock(), _createElementBlock("img", _hoisted_4))]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, _toDisplayString(item.alias), 1), item.status == 1 ? (_openBlock(), _createElementBlock("div", _hoisted_8, "Runing")) : item.status == 2 ? (_openBlock(), _createElementBlock("div", _hoisted_9, "Finish")) : item.status == 3 ? (_openBlock(), _createElementBlock("div", _hoisted_10, "Reject")) : (_openBlock(), _createElementBlock("div", _hoisted_11, "Review"))]), _createElementVNode("div", _hoisted_12, [_createElementVNode("div", null, [_createTextVNode("Cost: "), _createElementVNode("span", _hoisted_13, _toDisplayString(item.gold) + " usdt", 1)]), _createElementVNode("div", _hoisted_14, _toDisplayString(item.invest_time) + " Days", 1)]), _createElementVNode("div", _hoisted_15, [_createElementVNode("div", null, [_createTextVNode("Income: "), _createElementVNode("span", _hoisted_16, _toDisplayString(item.receiveds) + " usdt", 1)]), _createElementVNode("div", _hoisted_17, _toDisplayString(item.create_time), 1)])])])]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      }, 8, ["loading", "finished"]), empty.value ? (_openBlock(), _createBlock(_component_van_empty, {
        key: 0,
        description: "Empty"
      })) : _createCommentVNode("", true)], 64);
    };
  }
};